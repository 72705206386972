import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Component} from "@angular/core";

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls:['error.component.css']
})
export class ErrorComponent {
  public message: string;

  constructor(public activeDialog: NgbActiveModal) {

  }
}
