import {Component, Inject} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Album} from '../../models/Album';
import {SpinnerService} from "../../utils/services/SpinnerService";
import {PictureService} from "../../utils/services/PictureService";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-upload-pictures',
  templateUrl: './upload-pictures.component.html',
  styleUrls: ['./upload-pictures.component.css']
})
export class UploadPicturesComponent {

  public message: string;
  public messageType: string;

  public album: Album;

  public newPictures: string[];
  public pictures: Blob[];

  constructor(private httpClient: HttpClient, @Inject('BASE_URL') private baseUrl: string, public activeModal: NgbActiveModal, private spinner: SpinnerService, private pictureService: PictureService, private translateService: TranslateService) {
    this.album = new Album();
    this.newPictures = [];
    this.pictures = [];
  }

  setAlbum(album: Album): void {
    this.album = album;
  }

  detectFiles(event) {
    this.spinner.show();
    this.newPictures = [];
    let files = event.target.files;
    var loadedImages = 0;
    if (files) {
      for (let file of files) {
        this.pictureService.getOrientation(file, (orientation) => {
          const pictureList = this.newPictures;
          this.pictureService.getBase64(file, orientation, (im) => {
            loadedImages++;
            if(files.length == loadedImages)
              this.spinner.hide();

            pictureList.push(im);
          });
        });
        this.pictures.push(file);
      }
    }
  }

  save(): void {
    if(this.pictures.length > 0)
      this.uploadPicture(0);
  }

  uploadPicture(position: number) {
    const data = new FormData();
    data.append("picture" + position.toString(), this.pictures[position]);
    const message = this.translateService.instant("UploadingImageXOfY", { current: (position+1), total: this.pictures.length });
    this.spinner.updateText(message);
    this.httpClient.post(this.baseUrl + "api/photoalbums/" + this.album.id + "/add-picture", data).subscribe(x => {
      if (position < (this.pictures.length - 1))
        this.uploadPicture(position+1);
      else {
        this.spinner.resetToDefaultText();
        this.activeModal.close();
      }
    }, error => {
      this.spinner.resetToDefaultText();

    });
  }
}
