import { Component, Inject } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { Report } from "../../models/Report";
import { ManageReportComponent } from "./manage-report.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-manage-reports',
  templateUrl: './manage-reports.component.html',
  styleUrls: ['./manage-reports.component.css']
})
export class ManageReportsComponent {

  public message: string;
  public messageType: string;
  public reports: Report[];

  constructor(private httpClient: HttpClient, @Inject('BASE_URL') private baseUrl: string, private modalService: NgbModal, private translateService: TranslateService) {
    this.loadReports();
  }

  loadReports(): void {
    this.httpClient.get<Report[]>(this.baseUrl + "api/reports").subscribe(x => {
      this.reports = x;
    });
  }

  deleteReport(rep: Report): void {
    this.httpClient.delete(this.baseUrl + "api/reports/" + rep.id).subscribe(x => {
      this.loadReports();
      this.message = this.translateService.instant("SuccessfullyDeleted");
      this.messageType = "success";
      this.letMessageDisappear();
    }, err => {
        this.message = this.translateService.instant("SomethingWentWrongWhileDeleting");
      this.messageType = "danger";
    });
  }

  addNewReport(): void {
    const modalRef = this.modalService.open(ManageReportComponent, { size: "lg" });
    modalRef.result.then((x: any) => {
      this.loadReports();
    });
  }

  editReport(report: Report): void {
    const modalRef = this.modalService.open(ManageReportComponent, { size: "lg" });
    modalRef.componentInstance.setReport(report);
    modalRef.result.then((x: any) => {
      this.loadReports();
    });
  }

  letMessageDisappear() {
    setTimeout(() => {
      this.message = null;
    }, 4000);
  }
}
