import {Component, Inject} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AddCalenderItemComponent} from './add-calendar-item.component';
import {Event} from '../../models/Event';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-manage-calendar',
  templateUrl: './calendar.component.html',
  styleUrls:['./calendar.component.css']
})
export class ManageCalendarComponent {

  public message: string;
  public  messageType: string;

  public events: Event[];


  constructor(private httpClient: HttpClient,  @Inject('BASE_URL') private baseUrl: string,private modalService: NgbModal, private translateService: TranslateService) {
    this.loadEvents();
  }

  loadEvents() : void {
    this.httpClient.get<Event[]>(this.baseUrl + "api/events").subscribe(x => {
      this.events = x;
    });
  }

  deleteEvent(ev : Event) : void {
    this.httpClient.delete(this.baseUrl + "api/events/" + ev.id).subscribe(x => {
      this.loadEvents();
      this.message = this.translateService.instant("SuccessfullyDeleted");
      this.messageType = "success";
      this.letMessageDisappear();
    }, err => {
        this.message = this.translateService.instant("SomethingWentWrongWhileDeleting");
      this.messageType = "danger";
    });
  }

  private letMessageDisappear() {
    setTimeout(() => {
      this.message = null;
    }, 4000);
  }

  addNewEvent(): void {
    const modalRef = this.modalService.open(AddCalenderItemComponent, { size: "lg" });
    modalRef.result.then((x: any) => {
      this.loadEvents();
    });

  }
    editEvent(ev : Event) : void {
      const modalRef = this.modalService.open(AddCalenderItemComponent, { size: "lg" });
      modalRef.componentInstance.setEvent(ev);
      modalRef.result.then((x: any) => {
        this.loadEvents();
      });
  }
}
