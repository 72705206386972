import {Component, Inject} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Category, Classification, Gender, TeamMember} from '../models/TeamMember';
import {DomSanitizer} from '@angular/platform-browser';
import {TeamMemberDetailComponent} from './teammember-detail.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-teammember-overview',
  templateUrl: './teammember-overview.component.html',
  styleUrls:['./teammember-overview.component.css']
})
export class TeamMemberOverviewComponent {

  public teamMembers: TeamMember[];
  public isYoung: boolean;
  public isTop: boolean;
  public isElite: boolean;

  constructor(private httpClient: HttpClient, @Inject('BASE_URL') private baseUrl: string, private sanitizer: DomSanitizer, private modalService: NgbModal,private route: ActivatedRoute) {
    this.route.data.subscribe(x => {
        this.isYoung = x["isYoung"];
        this.isTop = x["top"];
        this.isElite = x["elite"];
        this.loadMembers();
    });
  }

  loadMembers() {
    this.httpClient.get<TeamMember[]>(this.baseUrl + "api/team").subscribe(x => {
      this.teamMembers = x.filter(y => this.createFilter(y));
      this.teamMembers.forEach(member => {
        if (member.pictureInBase64) {
        }
        member.safeUrl = this.sanitizer.bypassSecurityTrustUrl(member.pictureInBase64);
      });
    });
  }


  hasCorrectAge(birthDate: Date) : boolean {
    const timeDiff = Math.abs(Date.now() - birthDate.getTime());
    const age: number = Math.floor((timeDiff / (1000 * 3600 * 24))/365);
    if(this.isYoung) {
      return age < 18;
    } else {
      return age >= 18;
    }
  }

  getCategoryName(cat: Category) : string {
    return Category[cat];
  }


  details(teamMember: TeamMember): void {
    const modalRef = this.modalService.open(TeamMemberDetailComponent, { size: "lg" });
    modalRef.componentInstance.setTeamMember(teamMember);
  }

  getGenderName(gender: Gender) : string {
    return Gender[gender];
  }

  private createFilter(y: TeamMember) : boolean {
    if(this.isYoung)
      return y.category != Category.Junior && y.category != Category.Senior && y.category != Category.Master;
    else if(this.isTop)
      return y.classification == Classification.Top && (y.category == Category.Junior || y.category == Category.Senior || y.category == Category.Master);
    else if(this.isElite)
      return y.classification == Classification.Elite && (y.category == Category.Junior || y.category == Category.Senior || y.category == Category.Master);
    else {
      return (y.classification != Classification.Top && y.classification != Classification.Elite) && (y.category == Category.Junior || y.category == Category.Senior || y.category == Category.Master);
    }
  }
}
