import {Component, Inject} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {Report} from "../models/Report";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls:['./home.component.css']
})
export class HomeComponent {

  public  reports: Report[];

  constructor(private httpClient: HttpClient, @Inject('BASE_URL') private baseUrl: string, private sanitizer: DomSanitizer, private modalService: NgbModal) {
    this.loadLastReports();
  }

  loadLastReports() : void {
    this.httpClient.get<Report[]>(this.baseUrl + "api/reports/last-3").subscribe(x => {
      this.reports = x;
    });
  }
}
