export enum Roles {
  Admin = "Admin",
  User = "User",
  CalendarManager = "CalendarManager",
  NewsitemsManager = "NewsitemsManager",
  PhotoAlbumManager = "PhotoAlbumManager",
  SponsorManager = "SponsorManager",
  PartnerManager = "PartnerManager",
  InfoManager = "InfoManager"
}
