import {Component, Inject, Sanitizer} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {DomSanitizer} from '@angular/platform-browser';
import {NgbCarouselConfig, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Sponsor, SponsorType} from "../models/Sponsor";

@Component({
  selector: 'app-sponsor-banner',
  templateUrl: './sponsor-banner.component.html',
  styleUrls: ['./sponsor-banner.component.css'],
})
export class SponsorBannerComponent {

  public sponsors: Sponsor[];
  public selectedSponsor: Sponsor;
  public transitioning: boolean;
  private types: any;

  constructor(private httpClient: HttpClient, @Inject('BASE_URL') private baseUrl: string, private sanitizer: DomSanitizer, private modalService: NgbModal) {
    this.loadSponsors();
    this.types = SponsorType;
    this.transitioning = false;
  }

  loadSponsors() {
    this.httpClient.get<Sponsor[]>(this.baseUrl + "api/sponsors").subscribe(x => {
      this.sponsors = x;
      this.sponsors.forEach(sponsor => {
        if (sponsor.banner)
          sponsor.bannerImage = this.sanitizer.bypassSecurityTrustResourceUrl(sponsor.banner);
      });
      if (this.sponsors.length > 0) {
        this.selectSlide(0);
      }
    });
  }

  selectSlide(index: number): void {
    var lastIndex = this.sponsors.length - 1;
    this.transitioning = true;
    setTimeout(() => {
      this.selectedSponsor = this.sponsors[index];
      this.transitioning = false;
    }, 400);
    setTimeout(() => {
      index = index + 1;
      if (index > lastIndex)
        index = 0;
      this.selectSlide(index);
    }, 3000);
  }

  getType(type: SponsorType): string {
    return this.types[type];
  }
}
