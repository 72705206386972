import {Component, Inject} from "@angular/core";
import {HttpClient} from '@angular/common/http';
import {Report} from "../../models/Report";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {TranslateService} from "@ngx-translate/core";
import {ManageReportComponent} from "../reports/manage-report.component";
import {ManageSponsorComponent} from "./manage-sponsor.component";
import {Sponsor, SponsorType} from "../../models/Sponsor";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-manage-sponsors',
  templateUrl: './manage-sponsors.component.html',
  styleUrls: ['./manage-sponsors.component.css']
})
export class ManageSponsorsComponent {

  public message: string;
  public messageType: string;
  public sponsors: Sponsor[];

  constructor(private httpClient: HttpClient, @Inject('BASE_URL') private baseUrl: string, private sanitizer: DomSanitizer, private modalService: NgbModal, private translateService: TranslateService) {
    this.loadSponsors();
  }

  loadSponsors(): void {
    this.httpClient.get<Sponsor[]>(this.baseUrl + "api/sponsors").subscribe(x => {
      this.sponsors = x;
      x.forEach(y => {
        if(y.banner != null)
          y.bannerImage = this.sanitizer.bypassSecurityTrustUrl(y.banner);
      });
    });
  }

  upload(files: File[], sponsorId: number) {
    let payload = new FormData();
    payload.append('banner', files[0]);
    this.httpClient.put(this.baseUrl + 'api/sponsors/' + sponsorId + "/banner", payload).subscribe(x => {
      this.loadSponsors();
    });
  }

  private editBanner(sponsor: Sponsor, button: HTMLInputElement) {
    button.click();
  }

  private deleteBanner(sponsor: Sponsor) {
    sponsor.banner = null;
    this.httpClient.put(this.baseUrl + "api/sponsors", sponsor).subscribe(x => {
      this.loadSponsors();
    });
  }

  deleteSponsor(sponsor: Sponsor): void {
    this.httpClient.delete(this.baseUrl + "api/sponsors/" + sponsor.id).subscribe(x => {
      this.loadSponsors();
      this.message = this.translateService.instant("SuccessfullyDeleted");
      this.messageType = "success";
      this.letMessageDisappear();
    }, err => {
      this.message = this.translateService.instant("SomethingWentWrongWhileDeleting");
      this.messageType = "danger";
    });
  }

  addNewSponsor(): void {
    const modalRef = this.modalService.open(ManageSponsorComponent, {size: "lg"});
    modalRef.result.then((x: any) => {
      this.loadSponsors();
    });
  }

  getSponsorType(type: SponsorType): string {
    return SponsorType[type];
  }

  editSponsor(sponsor: Sponsor): void {
    const modalRef = this.modalService.open(ManageSponsorComponent, {size: "lg"});
    modalRef.componentInstance.setSponsor(sponsor);
    modalRef.result.then((x: any) => {
      this.loadSponsors();
    });
  }

  letMessageDisappear() {
    setTimeout(() => {
      this.message = null;
    }, 4000);
  }
}
