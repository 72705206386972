import {SafeUrl} from "@angular/platform-browser";

export class Sponsor {
  id: number;
  name: string;
  type: SponsorType;
  banner: string;
  bannerImage: SafeUrl;
  website: string;
  extraInfo: string;
}

export enum SponsorType {
  Gold,
  Silver,
  Bronze
}
