import {Component, Inject} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Category, TeamMember} from '../../models/TeamMember';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ManageTeamMemberComponent} from './manage-teammember.component';
import { DomSanitizer } from '@angular/platform-browser';
import { CoupleTeamMemberComponent } from './couple-teammember.component';
import { TranslateService } from '@ngx-translate/core';
import {ManageBestTimeComponent} from "./manage-best-time.component ";

@Component({
  selector: 'app-manage-team-members',
  templateUrl: './teammembers.component.html',
  styleUrls:['./teammembers.component.css']
})
export class ManageTeamMembersComponent {

  public teamMembers: TeamMember[];

  public message: string;
  public messageType: string;

  constructor(private httpClient: HttpClient, @Inject('BASE_URL') private baseUrl: string, private modalService: NgbModal, private sanitizer: DomSanitizer, private translateService: TranslateService) {
    this.loadMembers();
  }

  loadMembers() {
    this.httpClient.get<TeamMember[]>(this.baseUrl + "api/team").subscribe(x => {
      x.forEach(member => {
        if (member.pictureInBase64) {
        }
        member.safeUrl = this.sanitizer.bypassSecurityTrustUrl(member.pictureInBase64);
      });
      this.teamMembers = x;
    });
  }

   getCategoryName(cat: Category) : string {
    return Category[cat];
  }

  delete(member:TeamMember) : void {
    this.httpClient.delete(this.baseUrl + "api/team/" + member.id).subscribe(x => {
      this.teamMembers.splice(this.teamMembers.indexOf(member),1);
      this.message = this.translateService.instant("SuccessfullyDeleted");
      this.messageType = "success";
      this.letMessageDisappear();
    }, err => {
        this.message = this.translateService.instant("SomethingWentWrongWhileDeleting");
      this.messageType = "danger";
    });
  }

  edit(member: TeamMember): void {
    const modalRef = this.modalService.open(ManageTeamMemberComponent, { size: "lg" });
    modalRef.componentInstance.setTeamMember(member);
    modalRef.result.then(x => {
      this.loadMembers();
    });
  }

  editResults(member: TeamMember): void {
    const modalRef = this.modalService.open(ManageBestTimeComponent, { size: "lg" });
    modalRef.componentInstance.setTeamMember(member);
    modalRef.result.then(x => {
      this.loadMembers();
    });
  }

  couple(member: TeamMember): void {
    const modalRef = this.modalService.open(CoupleTeamMemberComponent);
    modalRef.componentInstance.setTeamMember(member);
    modalRef.result.then(x => {
      this.loadMembers();
    });
  }

  decouple(userId: number): void {
    this.httpClient.put(this.baseUrl + "api/users/decouple/" + userId, null).subscribe(x => {
      this.loadMembers();
    });
  }

  addNewMember() {
    const modalRef = this.modalService.open(ManageTeamMemberComponent, { size: "lg" });
    modalRef.result.then((x: TeamMember) => {
      if(x) {
        this.loadMembers();
        this.message = this.translateService.instant("SuccessfullyAdded");
        this.messageType = "success";
        this.letMessageDisappear();
      }
    });
  }

  private letMessageDisappear() {
    setTimeout(() => {
      this.message = null;
    }, 4000);
  }
}
