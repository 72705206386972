import {Component, Inject} from "@angular/core";
import {HttpClient} from '@angular/common/http';
import {Report} from "../../models/Report";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {TranslateService} from "@ngx-translate/core";
import {ManageReportComponent} from "../reports/manage-report.component";
import {ManageSponsorComponent} from "./manage-sponsor.component";
import {Sponsor, SponsorType} from "../../models/Sponsor";
import {DomSanitizer} from "@angular/platform-browser";
import { Partner } from "../../models/Partner";
import { ManagePartnerComponent } from "./manage-partner.component";

@Component({
  selector: 'app-manage-partners',
  templateUrl: './manage-partners.component.html',
  styleUrls: ['./manage-partners.component.css']
})
export class ManagePartnersComponent {

  public message: string;
  public messageType: string;
  public partners: Partner[];

  constructor(private httpClient: HttpClient, @Inject('BASE_URL') private baseUrl: string, private sanitizer: DomSanitizer, private modalService: NgbModal, private translateService: TranslateService) {
    this.loadPartners();
  }

  loadPartners(): void {
    this.httpClient.get<Partner[]>(this.baseUrl + "api/partners").subscribe(x => {
      this.partners = x;
      x.forEach(y => {
        if(y.banner != null)
          y.bannerImage = this.sanitizer.bypassSecurityTrustUrl(y.banner);
      });
    });
  }

  upload(files: File[], partnerId: number) {
    let payload = new FormData();
    payload.append('banner', files[0]);
    this.httpClient.put(this.baseUrl + 'api/partners/' + partnerId + "/banner", payload).subscribe(x => {
      this.loadPartners();
    });
  }

  private editBanner(sponsor: Sponsor, button: HTMLInputElement) {
    button.click();
  }

  private deleteBanner(sponsor: Sponsor) {
    sponsor.banner = null;
    this.httpClient.put(this.baseUrl + "api/partners", sponsor).subscribe(x => {
      this.loadPartners();
    });
  }

  deleteSponsor(partner: Partner): void {
    this.httpClient.delete(this.baseUrl + "api/partners/" + partner.id).subscribe(x => {
      this.loadPartners();
      this.message = this.translateService.instant("SuccessfullyDeleted");
      this.messageType = "success";
      this.letMessageDisappear();
    }, err => {
      this.message = this.translateService.instant("SomethingWentWrongWhileDeleting");
      this.messageType = "danger";
    });
  }

  addNewPartner(): void {
    const modalRef = this.modalService.open(ManagePartnerComponent, {size: "lg"});
    modalRef.result.then((x: any) => {
      this.loadPartners();
    });
  }
  editPartner(partner: Partner): void {
    const modalRef = this.modalService.open(ManagePartnerComponent, {size: "lg"});
    modalRef.componentInstance.setPartner(partner);
    modalRef.result.then((x: any) => {
      this.loadPartners();
    });
  }

  letMessageDisappear() {
    setTimeout(() => {
      this.message = null;
    }, 4000);
  }
}
