import { DateFormatterParams, CalendarNativeDateFormatter } from "angular-calendar";
import { Injectable } from "@angular/core";

@Injectable()
export class CustomDateFormatter extends CalendarNativeDateFormatter {

  public dayViewHour({date, locale}: DateFormatterParams): string {
    // change this to return a different date format
    return new Intl.DateTimeFormat(locale, {hour: 'numeric'}).format(date);
  }

}
