import {Component, Inject} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Category, Gender, TeamMember} from '../models/TeamMember';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-teammember-detail',
  templateUrl: './teammember-detail.component.html',
  styleUrls: ['./teammember-detail.component.css']
})
export class TeamMemberDetailComponent {

  public teamMember: TeamMember;

  constructor(private httpClient: HttpClient, @Inject('BASE_URL') private baseUrl: string, private sanitizer: DomSanitizer, public activeModal: NgbActiveModal) {

  }
  getCategoryName(cat: Category): string {
    return Category[cat];
  }


  setTeamMember(member: TeamMember): void {
    this.teamMember = member;
  }
}
