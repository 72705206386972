import { Validator, ValidationErrors, AbstractControl, ValidatorFn, FormGroup, NG_VALIDATORS } from "@angular/forms";
import { Directive } from "@angular/core";

@Directive({
  selector: '[DateAfterDateValidator]',
  providers: [{ provide: NG_VALIDATORS, useExisting: DateAfterDateValidatorDirective, multi: true }]
})
export class DateAfterDateValidatorDirective implements Validator {
  validate(control: AbstractControl): ValidationErrors {
    return dateAfterDateValidator(control)
  }
}

export const dateAfterDateValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
  const start = control.get('startDate');
  const starttime = control.get('startTime');
  const end = control.get('endDate');
  const endtime = control.get('endTime');

  if (start && start.value) {
    if (starttime && starttime.value && starttime.value.hour) {
      start.value.setHours(starttime.value.hour);
    } else {
      start.value.setHours(0);
    }
    if (starttime && starttime.value && starttime.value.minute) {
      start.value.setMinutes(starttime.value.minute);
    } else {
      start.value.setMinutes(0);
    }
  }
  if (end && end.value) {
    if (endtime && endtime.value && endtime.value.hour) {
      end.value.setHours(endtime.value.hour);
    } else {
      end.value.setHours(0);
    }
    if (endtime && endtime.value && endtime.value.minute) {
      end.value.setMinutes(endtime.value.minute);
    } else {
      end.value.setMinutes(0);
    }
  }

  return start && end && start.value > end.value ? { 'datesAreNotCorrect': true } : null;
};
