import { Injectable, Injector } from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import {Observable, throwError} from 'rxjs';
import {AuthService} from '../services/AuthService';
import { catchError, tap } from 'rxjs/operators';
import {ErrorService} from "../services/ErrorService";
import {SpinnerService} from "../services/SpinnerService";


@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {
  private authService : AuthService;

  private counter: number;

  constructor(private injector: Injector, private router: Router, private spinner: SpinnerService, private errorService: ErrorService) {
    this.counter = 0;
  }

  initLoader(): void {
    this.counter++;
    this.spinner.show();
  }

  hideLoader(): void {
    this.counter--;
    if(this.counter == 0)
      this.spinner.hide();
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.initLoader();
    this.authService = this.injector.get(AuthService);
    // Get the auth header from the service.
    const Authorization = this.authService.getToken();
    var newReq = req;
    if(Authorization != null) {
      // Clone the request to add the new header.
      newReq = req.clone({ headers: req.headers.set('authorization', "Bearer " + Authorization) });
    }
    // Pass on the cloned request instead of the original request.
    return next.handle(newReq)
      .pipe(catchError((err) => {
        this.hideLoader();
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            this.router.navigate(['/signin']);
          } else {
            this.spinner.resetToDefaultText();
            //this.errorService.show(err.message);
          }
        }
        else {
          this.spinner.resetToDefaultText();
          console.log("other error");
        }
        return throwError(err);
      })).pipe(tap((event: HttpEvent<any>) => {
        if(event instanceof HttpResponse) {
          this.hideLoader();
        }
    }));
  }
}
