import { Component, ElementRef, Renderer2 } from '@angular/core';
import {AuthService} from '../utils/services/AuthService';
import {combineLatest, Observable, of} from 'rxjs';
import {Router} from '@angular/router';
import {TranslateService} from "@ngx-translate/core";
import {Roles} from "../models/Roles";

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent  {

  public isAuthenticated: Observable<boolean>;
  public isAdmin: Observable<boolean>;
  public isNormalUser: Observable<boolean>;

  constructor(private el: ElementRef, private router: Router, private renderer: Renderer2, private authService: AuthService, private translate: TranslateService) {
    this.isAuthenticated = this.authService.isAuthenticated();
    this.isAdmin = this.authService.isAdmin();
    this.isNormalUser = this.authService.isNormalUser();
  }

  onMenuClick() {
    this.renderer.removeClass(this.el.nativeElement.querySelector('.navbar-collapse'), 'show');
  }

  switchLanguage(language: string) : void {
    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang(language);
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.translate.use(language);
  }

  getCurrentFlag() : string {
    const lang = this.translate.currentLang;
    if(lang == "be")
      return "belgium";
    return "united-kingdom";
  }

  signout() {
    this.authService.removeTokens();
    this.router.navigateByUrl('/');
  }

  combineObservablesWithOr(first: Observable<boolean>, second: Observable<boolean>): Observable<boolean> {
    return combineLatest(first, second, (first, second) => first || second);
  }

  public ableToSee(menuItem: string): Observable<boolean> {
    switch (menuItem) {
      case "TeamMembers":
        return this.isAdmin;
      case "Coaches":
        return this.isAdmin;
      case "CalendarSettings":
        return this.combineObservablesWithOr(this.isAdmin, this.authService.hasRole(Roles.CalendarManager));
      case "Pictures":
        return this.combineObservablesWithOr(this.isAdmin, this.authService.hasRole(Roles.PhotoAlbumManager));
      case "Reports":
        return this.combineObservablesWithOr(this.isAdmin, this.authService.hasRole(Roles.NewsitemsManager));
      case "Sponsors":
        return this.combineObservablesWithOr(this.isAdmin, this.authService.hasRole(Roles.SponsorManager));
      case "Partners":
        return this.combineObservablesWithOr(this.isAdmin, this.authService.hasRole(Roles.PartnerManager));
      case "Other":
        return this.combineObservablesWithOr(this.isAdmin, this.authService.hasRole(Roles.InfoManager));
      case "Users":
        return this.isAdmin;
      default:
        return of(false);
    }
  }
}
