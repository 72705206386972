import {ChangeDetectionStrategy, Component, Inject, TemplateRef, ViewChild} from '@angular/core';
import { CalendarView, CalendarEvent, DAYS_OF_WEEK  } from 'angular-calendar';
import {Subject} from 'rxjs';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {
  startOfDay,
  endOfDay,
  subDays,
  addDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours
} from 'date-fns';
import {CalendarEventDetailComponent} from './calendar-event-detail.component';
import {HttpClient} from '@angular/common/http';
import { Event, OwnCalendarEvent} from '../models/Event';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['calendar.component.css']
})
export class CalendarComponent {

  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  viewDate: Date = new Date();
  refresh: Subject<any> = new Subject();
  events: OwnCalendarEvent[] = [];
  activeDayIsOpen: boolean = false;
  locale: string;
  weekStartsOn: number = DAYS_OF_WEEK.MONDAY;
  weekendDays: number[] = [DAYS_OF_WEEK.FRIDAY, DAYS_OF_WEEK.SATURDAY];

  constructor(private modal: NgbModal, private httpClient: HttpClient, @Inject('BASE_URL') private baseUrl: string, private translateService: TranslateService) {
    this.loadEvents();
    this.setLocale();
    this.translateService.onLangChange.subscribe(x => {
      this.setLocale();
    });
  }

  setLocale() {
    const lang = this.translateService.currentLang;
    if(lang == "be") {
      this.locale = "nl";
    } else {
      this.locale = "en";
    }
  }

  loadEvents(): void {
    this.httpClient.get<Event[]>(this.baseUrl + 'api/events').subscribe(x => {
      this.events = x.map(ev => <OwnCalendarEvent>{
        id: ev.id,
        start: new Date(ev.start),
        end: new Date(ev.end),
        title: ev.title,
        description: ev.description,
        remarks: ev.remarks,
        place: ev.place
      });
      this.refresh.next();
    });
  }

  dayClicked({ date, events }: { date: Date; events: OwnCalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      this.viewDate = date;
      this.activeDayIsOpen = !((isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0);
    }
  }

  handleEvent(action: string, event: OwnCalendarEvent): void {
    const modalRef = this.modal.open(CalendarEventDetailComponent, {size: 'lg'});
    modalRef.componentInstance.calendarEvent = event;
  }
}
