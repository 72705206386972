import { Component, Inject } from '@angular/core';
import {Subject} from 'rxjs';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {HttpClient} from '@angular/common/http';
import { TeamMember, Category, Gender } from '../models/TeamMember';
import { User } from '../models/User';
import { AuthService } from '../utils/services/AuthService';
import { DomSanitizer } from '@angular/platform-browser';
import { ManageTeamMemberComponent } from '../admin/teammembers/manage-teammember.component';
import {ManageBestTimeComponent} from "../admin/teammembers/manage-best-time.component ";

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['profile.component.css']
})
export class ProfileComponent {

  public user: User;
  public teamMember: TeamMember;
 public createTeamMember: boolean;

  constructor(private modal: NgbModal, private httpClient: HttpClient, @Inject('BASE_URL') private baseUrl: string, private authService: AuthService, private sanitizer: DomSanitizer, private modalService: NgbModal) {
    this.loadInfo();
  }

  getCategoryName(cat: Category): string {
    return Category[cat];
  }

  getGenderName(gender: Gender): string {
    return Gender[gender];
  }

  editResults(member: TeamMember): void {
    const modalRef = this.modalService.open(ManageBestTimeComponent, { size: "lg" });
    modalRef.componentInstance.setTeamMember(member);
    modalRef.result.then(x => {
      this.loadInfo();
    });
  }

  edit(teamMember: TeamMember): void {
    const modalRef = this.modalService.open(ManageTeamMemberComponent, { size: "lg" });
    modalRef.componentInstance.setTeamMember(teamMember);
    modalRef.result.then(x => {
      this.loadInfo();
    });
  }

  newTeamMember(): void {
    const modalRef = this.modalService.open(ManageTeamMemberComponent, { size: "lg" });
    modalRef.componentInstance.setTeamMember({ user: { id: this.user.id }  });
    modalRef.result.then(x => {
      this.loadInfo();
    });
  }

  loadInfo(): void {
    this.createTeamMember = false;
    this.httpClient.get<User>(this.baseUrl + 'api/users/' + this.authService.getUserId()).subscribe(x => {
      this.user = x;
      if (x.coupledMemberId) {
        this.httpClient.get<TeamMember>(this.baseUrl + 'api/team/' + x.coupledMemberId).subscribe(y => {
          this.teamMember = y;
          this.teamMember.safeUrl = this.sanitizer.bypassSecurityTrustUrl(this.teamMember.pictureInBase64);
        });
      } else {
        this.createTeamMember = true;
      }
    });
  }
}
