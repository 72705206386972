import { CalendarEvent } from "angular-calendar";
import { extend } from "webdriver-js-extender";

export class Event {
  id: number;
  title: string;
  description: string;
  start: Date;
  remarks: string;
  isPubliclyVisible: boolean;
  end: Date;
  place: string;
}

export interface OwnCalendarEvent<MetaType = any> extends CalendarEvent<MetaType> {
  description: string;
  place: string;
  remarks: string;
}
