import {Component, Inject} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Category, Gender, TeamMember} from '../models/TeamMember';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {Coach} from "../models/Coach";

@Component({
  selector: 'app-coach-detail',
  templateUrl: './coach-detail.component.html',
  styleUrls: ['./coach-detail.component.css']
})
export class CoachDetailComponent {

  public coach: Coach;

  constructor(private httpClient: HttpClient, @Inject('BASE_URL') private baseUrl: string, private sanitizer: DomSanitizer, public activeModal: NgbActiveModal) {

  }

  setCoach(c: Coach): void {
    this.coach = c;
  }
}
