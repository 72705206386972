import { SafeUrl } from "@angular/platform-browser";
import {Gender} from './TeamMember';

export class Coach {
  id: number;
  firstName: string;
  lastName: string;
  pictureInBase64: string;
  safeUrl: SafeUrl;
  gender: Gender;
  certificates: string[];
  description: string;
}
