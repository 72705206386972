import {Component, Inject} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Event} from '../../models/Event';
import { Report } from '../../models/Report';
import { TinyMceSettingsService } from '../../utils/services/TinyMceSettingsService';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-manage-report',
  templateUrl: './manage-report.component.html',
  styleUrls:['./manage-report.component.css']
})
export class ManageReportComponent {

  public message: string;
  public  messageType: string;

  public tinyMceSettings: any;

  public rep: Report;
  public events: Event[];

  constructor(private httpClient: HttpClient, @Inject('BASE_URL') private baseUrl: string, public activeModal: NgbActiveModal, tinyMceService: TinyMceSettingsService) {
    this.rep = new Report();
    this.setReport(new Report());
    this.tinyMceSettings = tinyMceService.getSettings("100%", "320px");
  }

  setReport(rep: Report): void {
    this.httpClient.get<Event[]>(this.baseUrl + "api/events").subscribe(x => {
      this.events = x;

      this.rep = rep;
      if (this.rep.event != null)
        this.rep.event = this.events.find(y => y.id == rep.event.id)
    });
  }

  save(form: NgForm): void {
    if (form.valid) {
      if (!this.rep.id || this.rep.id == 0) {
        this.httpClient.post(this.baseUrl + "api/reports", this.rep).subscribe(x => {
          this.activeModal.close();
        });
      } else {
        this.httpClient.put(this.baseUrl + "api/reports", this.rep).subscribe(x => {
          this.activeModal.close();
        });
      }
    }
  }
}
