import {Component, Inject} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Category, TeamMember} from '../../models/TeamMember';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
import {Coach} from '../../models/Coach';
import {ManageCoachComponent} from './manage-coach.component';

@Component({
  selector: 'app-manage-coaches',
  templateUrl: './coaches.component.html',
  styleUrls:['./coaches.component.css']
})
export class ManageCoachesComponent {

  public coaches: Coach[];

  public message: string;
  public messageType: string;

  constructor(private httpClient: HttpClient, @Inject('BASE_URL') private baseUrl: string, private modalService: NgbModal, private sanitizer: DomSanitizer) {
    this.loadCoaches();
  }

  loadCoaches() {
    this.httpClient.get<Coach[]>(this.baseUrl + "api/coaches").subscribe(x => {
      x.forEach(coach => {
        if (coach.pictureInBase64) {
        }
        coach.safeUrl = this.sanitizer.bypassSecurityTrustUrl(coach.pictureInBase64);
      });
      this.coaches = x;
    });
  }

  edit(coach: Coach) : void {
    const modalRef = this.modalService.open(ManageCoachComponent, { size: "lg" });
    modalRef.componentInstance.setCoach(coach);
    modalRef.result.then(x => {
      this.loadCoaches();
    });
  }

  delete(coach:Coach) : void {
    this.httpClient.delete(this.baseUrl + "api/coaches/" + coach.id).subscribe(x => {
      this.coaches.splice(this.coaches.indexOf(coach),1);
      this.message = "Succesvol verwijderd!";
      this.messageType = "success";
      this.letMessageDisappear();
    }, err => {
      this.message = "Er is iets misgelopen tijdens het verwijderen";
      this.messageType = "danger";
    });
  }

  addNewCoach() {
    const modalRef = this.modalService.open(ManageCoachComponent);
    modalRef.result.then((x: Coach) => {
      if(x) {
        this.loadCoaches();
        this.message = "Succesvol toegevoegd!";
        this.messageType = "success";
        this.letMessageDisappear();
      }
    });
  }

  private letMessageDisappear() {
    setTimeout(() => {
      this.message = null;
    }, 4000);
  }
}
