import {Injectable} from "@angular/core";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ErrorComponent} from "../../other/error.component";

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  constructor(private modalService: NgbModal) {

  }

  show(errorMessage: string):  Promise<any>  {
    const dialog = this.modalService.open(ErrorComponent, {size: "lg"});
    dialog.componentInstance.message = errorMessage;
    return dialog.result;
  }
}
