import {Pipe, PipeTransform} from '@angular/core';
import {Gender} from '../../models/TeamMember';

@Pipe({name: 'genderIcon'})
export class GenderIconPipe implements PipeTransform {
  transform(value: Gender, ...args: any[]): string {
    if(value == Gender.Male) {
      return "fa-mars";
    } else
      return "fa-venus";
  }
}
