import { Component, Inject } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import {Report} from '../models/Report';
import {ActivatedRoute, Router} from "@angular/router";
import { Location } from '@angular/common';
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent {

  public  reports: Report[];
  private chosenId?: number;

  constructor(private sanitizer: DomSanitizer, private httpClient: HttpClient, @Inject('BASE_URL') private baseUrl: string,private route: ActivatedRoute, private router: Router, private location: Location) {
    this.httpClient.get<Report[]>(this.baseUrl + "api/reports").subscribe(x => {
      this.reports = x;
      this.chosenId = null;
      if(this.route.snapshot.paramMap.get('reportId')) {
        this.chosenId = Number(this.route.snapshot.paramMap.get('reportId'));
        if(this.chosenId) {
          setTimeout(() => this.scroll(this.chosenId), 500);
        }
      }
    });
  }

  fixHtml(htmlString: string) {
    return this.sanitizer.bypassSecurityTrustHtml(htmlString);
  }

  openReport(id: number) {
    const url = this
      .router
      .createUrlTree(['reports', id])
      .toString();
    this.location.go(url);
  }

  scroll(id : number) : void {
    const domId = "collapse" + id;
    let el = document.getElementById(domId);
    if(el) {
      el.scrollIntoView();
      window.scrollBy(0,-57);
    }
  }

  showIfNeeded(id: number, isFirst: boolean):boolean {
    if(!this.chosenId)
      return isFirst;
    const res = id === this.chosenId;
    if(res) {
       //setTimeout(() => this.scroll(id), 500);
    }
    return res;
  }
}
