export class Info {
  id: number;
  type: InfoType;
  text: string;
  title: string;
  mimeType: string;
  isPubliclyVisible: boolean;
  creationDate: Date;
  file: Blob;
}

export enum InfoType {
  Document,
  Text
}
