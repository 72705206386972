import {Component, Inject} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Event} from '../../models/Event';
import { Report } from '../../models/Report';
import {Album} from '../../models/Album';
import {Picture} from '../../models/Picture';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-manage-photoalbum',
  templateUrl: './manage-photoalbum.component.html',
  styleUrls:['./manage-photoalbum.component.css']
})
export class ManagePhotoalbumComponent {

  public message: string;
  public  messageType: string;

  public album: Album;
  public pictures: Picture[];
  public choosenPicture: Picture;

  constructor(private httpClient: HttpClient, @Inject('BASE_URL') private baseUrl: string, public activeModal: NgbActiveModal, private sanitizer: DomSanitizer) {
    this.album = new Album();
    this.choosenPicture = new Picture();

  }

  chooseImage(picture: Picture) {
      this.choosenPicture = picture;
  }

  deleteImage(picture: Picture) {
    this.httpClient.delete(this.baseUrl + "api/photoalbums/" + this.album.id + "/" + picture.id).subscribe(x => {
      if(this.choosenPicture == picture)
        this.choosenPicture = new Picture();

      const index = this.pictures.indexOf(picture);
      if (index > -1) {
        this.pictures.splice(index, 1);
      }
    });
  }

  setAlbum(album: Album): void {
    this.album = album;
    this.httpClient.get<Picture[]>(this.baseUrl + "api/photoalbums/" + this.album.id + "/thumbnails").subscribe(x => {
      x.forEach(pic => {
        if(pic.id == this.album.mainPictureId) {
          this.choosenPicture = pic;
        }
      });
      this.pictures = x;
    });
  }

  save(): void {
    if (!this.album.id || this.album.id == 0) {
      this.httpClient.post(this.baseUrl + "api/photoalbums", this.album).subscribe(x => {
        this.activeModal.close();
      });
    } else {
      if(this.choosenPicture && this.choosenPicture.id != null)
        this.album.mainPictureId = this.choosenPicture.id;
      this.httpClient.put(this.baseUrl + "api/photoalbums", this.album).subscribe(x => {
        this.activeModal.close();
      });
    }
  }
}
