import { Component, Inject, AfterViewInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {NgbActiveModal, NgbDate, NgbDateStruct, NgbTimepicker } from '@ng-bootstrap/ng-bootstrap';
import { Event } from '../../models/Event';
import {TranslateService} from "@ngx-translate/core";
import { NgForm, NgModel } from '@angular/forms';
import {TinyMceSettingsService} from "../../utils/services/TinyMceSettingsService";

@Component({
  selector: 'app-add-calendar-item',
  templateUrl: './add-calendar-item.component.html',
  styleUrls: ['./add-calendar-item.component.css']
})
export class AddCalenderItemComponent implements AfterViewInit {

  public message: string;
  public messageType: string;

  public ev: Event;
  public starttime: any;
  public endtime: any;
  @ViewChild('f', {static: true}) formReference: NgForm;
  @ViewChild('startTime', { static: true }) endTimeReference: NgModel;
  @ViewChild('endTime', { static: true }) startTimeReference: NgModel;
  public endDisabled: boolean;
  public startDateMin: NgbDateStruct;
  public isDisabled: (date: NgbDate, current: { year: number; month: number; }) => any;
  public tinyMceSettings: any;

  constructor(private httpClient: HttpClient, @Inject('BASE_URL') private baseUrl: string, public activeModal: NgbActiveModal, private translateService: TranslateService, tinyMceService: TinyMceSettingsService) {
    this.ev = new Event();
    this.starttime = {};
    this.endtime = {};
    this.endDisabled = true;
    this.startDateMin = {year: 2019, month: 1, day: 1 };
    this.tinyMceSettings = tinyMceService.getSettings("100%", "320px");
    this.isDisabled = (date: NgbDate,  current: { year: number; month: number; }) =>
    {
      const otherDate = new NgbDate(this.ev.start.getUTCFullYear(), this.ev.start.getUTCMonth() + 1, this.ev.start.getUTCDate());
      return !this.ev || !this.ev.start || date.before(otherDate);
    };
  }

  ngAfterViewInit(): void {
    this.formReference.addControl(this.startTimeReference);
    this.formReference.addControl(this.endTimeReference);
    this.startTimeReference.control.updateValueAndValidity();
  }

  onDateSelect(eve: any): void {
    if(this.ev.start)
    {
      this.startDateMin = {
        year: this.ev.start.getFullYear(),
        month: this.ev.start.getMonth() + 1,
        day: this.ev.start.getDate()
      };
    }
    this.endDisabled = this.ev.start == null;
  }

  setEvent(event: Event): void {
    this.ev = event;
    this.starttime = {
      hour: this.ev.start.getHours(),
      minute: this.ev.start.getMinutes()
    };
    this.endtime = {
      hour: this.ev.end.getHours(),
      minute: this.ev.end.getMinutes()
    };
    this.endDisabled = this.ev.start == null;
  }

  save(form: NgForm): void {
    if(this.ev.start) {
      if (this.starttime && this.starttime.hour) {
        this.ev.start.setHours(this.starttime.hour);
      } else {
        this.ev.start.setHours(0);
      }
      if (this.starttime && this.starttime.minute) {
        this.ev.start.setMinutes(this.starttime.minute);
      } else {
        this.ev.start.setMinutes(0);
      }
    }
    if(this.ev.end) {
      if (this.endtime && this.endtime.hour) {
        this.ev.end.setHours(this.endtime.hour);
      } else {
        this.ev.end.setHours(0);
      }
      if (this.endtime && this.endtime.minute) {
        this.ev.end.setMinutes(this.endtime.minute);
      } else {
        this.ev.end.setMinutes(0);
      }
    }
    if(form.valid) {
      if (!this.ev.id || this.ev.id == 0) {
        this.httpClient.post(this.baseUrl + "api/events", this.ev).subscribe(x => {
          this.activeModal.close();
        });
      } else {
        this.httpClient.put(this.baseUrl + "api/events", this.ev).subscribe(x => {
          this.activeModal.close();
        });
      }
    }
  }

  private letMessageDisappear(time: number) {
    setTimeout(() => {
      this.message = null;
    }, time);
  }
}
