import {Component, Inject} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Category, Classification, Gender, TeamMember, CoupledUser} from '../../models/TeamMember';


@Component({
  selector: 'app-couple-team-member',
  templateUrl: './couple-teammember.component.html',
  styleUrls:['./couple-teammember.component.css']
})
export class CoupleTeamMemberComponent {

  public teamMember: TeamMember;
  public notCoupledUsers: CoupledUser[];
  public selectedUser: CoupledUser;

  constructor(private httpClient: HttpClient, @Inject('BASE_URL') private baseUrl: string, public activeModal: NgbActiveModal) {
  }

  setTeamMember(member: TeamMember): void {
    this.teamMember = member;
    this.httpClient.get<CoupledUser[]>(this.baseUrl + "api/users/not-coupled").subscribe(x => {
      this.notCoupledUsers = x;
    });
  }

  save(): void {
    if (this.selectedUser != null) {
      this.httpClient.put(this.baseUrl + "api/users/couple", { teamMemberId: this.teamMember.id, userId: this.selectedUser.id }).subscribe(x => {
        this.activeModal.close();
      });
    } else {
      this.activeModal.close();
    }
  }
}
