import {Component, Inject} from '@angular/core';
import {Mail} from "../models/Mail";
import {NgForm} from "@angular/forms";
import {HttpClient} from "@angular/common/http";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent {
  public mail: Mail;
  public error: string;
  public showMessage: boolean;
  public recaptcha: any;

  constructor(private httpClient: HttpClient,  @Inject('BASE_URL') private baseUrl: string, private translateService: TranslateService) {
    this.mail = new Mail();
  }

  contact(form: NgForm) : void {
    if(form.valid) {
      this.httpClient.post(this.baseUrl + "api/email", this.mail).subscribe(x => {
        this.error = null;
        this.showMessage = true;
        this.mail = new Mail();
        form.reset();
        setTimeout(() => this.showMessage = false, 6000);
      }, error => {
        this.error = this.translateService.instant("TryAgain");
        this.showMessage = true;
        setTimeout(() => this.showMessage = false, 6000);
      });
    }
  }
}
