import {Component, Inject} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {User} from '../models/User';
import {AuthService} from '../utils/services/AuthService';
import {ActivatedRoute, Router} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['signin.component.css']
})
export class SignInComponent {

  public username: string;
  public password: string;

  public errorMessage: string;
  public showErrorMessage: boolean = false;

  constructor(private route: ActivatedRoute, private httpClient: HttpClient, private router: Router, private authService: AuthService, @Inject('BASE_URL') private baseUrl: string, private translateService: TranslateService) {

  }

  signin(): void {
    this.httpClient.post(this.baseUrl + 'api/authenticate', {username: this.username, password: this.password}).subscribe(
      (resp: any) => {

        this.route.queryParams.subscribe((params) => {
          var user = new User();
          user.name = resp.username;
          user.username = resp.username;
          this.authService.setUser(user);
          this.authService.setToken(resp.token);
          this.authService.isAuthenticated().subscribe(() => {
            if (params['returnUrl']) {
              this.router.navigateByUrl(params['returnUrl']);
            }
            else {
              this.router.navigate(['/']);
            }
          });
        });
      }, (error: HttpErrorResponse) => {
        this.showErrorMessage = true;
        this.errorMessage = this.translateService.instant("InvalidCredentials");
        if (error.status != 400)
          this.errorMessage = this.translateService.instant("SomethingWentWrong");

        setTimeout(() => {
          this.showErrorMessage = false;
        }, 2000);
      });
  }
}
