import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot
} from '@angular/router';
import decode from 'jwt-decode';
import { AuthService } from '../services/AuthService';
import {Roles} from "../../models/Roles";

@Injectable()
export class RoleAuthGuard implements CanActivate {
  constructor(public auth: AuthService, public router: Router) {}
  canActivate(route: ActivatedRouteSnapshot): boolean {
    // this will be passed from the route config
    // on the data property
    const expectedRole = route.data.expectedRole;
    if (
      !this.auth.isAuthenticated() ||
      //no specific role and no admin role
      (this.auth.getUserRoles().indexOf(expectedRole) == - 1 &&
      this.auth.getUserRoles().indexOf(Roles.Admin) == - 1)
    ) {
      this.router.navigate(['signin']);
      return false;
    }
    return true;
  }
}
