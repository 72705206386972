import { PipeTransform, Pipe } from "@angular/core";

@Pipe({name: 'enumlist'})
export class EnumListPipe implements PipeTransform {
  transform(value, args: string[]): any {
    let myEnum = [];
    let objectEnum = Object.keys(value);
    const values = objectEnum.slice(0, objectEnum.length / 2);
    const keys = objectEnum.slice(objectEnum.length / 2);

    for (let i = 0; i < objectEnum.length / 2; i++) {
      myEnum.push({ key: keys[i], value: values[i] });
    }
    return myEnum;
  }
}
