import {Component, Inject} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {DomSanitizer} from '@angular/platform-browser';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Info, InfoType} from "../models/Info";
import {ResponseContentType} from "@angular/http";
import {FileSaverService} from "ngx-filesaver";

@Component({
  selector: 'app-other',
  templateUrl: './other.component.html',
  styleUrls:['./other.component.css']
})
export class OtherComponent {

  public documents:Info[];
  public texts:Info[];

  constructor(private httpClient: HttpClient, @Inject('BASE_URL') private baseUrl: string, private sanitizer: DomSanitizer, private modalService: NgbModal, private fileSaverService: FileSaverService) {
    this.loadInfo();
  }

  loadInfo(): void {
    this.httpClient.get<Info[]>(this.baseUrl + "api/info").subscribe(x => {
      this.documents = x.filter(x => x.type == InfoType.Document);
      this.texts = x.filter(x => x.type == InfoType.Text);
    });
  }

  download(doc: Info): void {
    this.httpClient.get(this.baseUrl + "api/content/docs/" + doc.id, {
      responseType: 'blob'
    }).subscribe((res:any) => {
      this.fileSaverService.save(res, doc.text);
    });
  }
}
