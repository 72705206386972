import {Injectable} from "@angular/core";
import {CookieService} from "ngx-cookie-service";
import {BehaviorSubject, Observable, of} from 'rxjs';
import {User} from "../../models/User"
import decode from 'jwt-decode';
import {map} from "rxjs/operators";
import {Roles} from "../../models/Roles";

@Injectable()
export class AuthService {

  private token_name = "token";
  private role_path = "http://schemas.microsoft.com/ws/2008/06/identity/claims/role";
  private username_path = "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name";
  private id_path = "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/sid";
  private normalUserRole = "User";

  private loggedIn = new BehaviorSubject<boolean>(false);
  private user = new BehaviorSubject<User>(null);

  constructor(private cookieService: CookieService) {
    var token = this.getToken();
    if(token && token != null)
      this.loggedIn.next(true);
    else
      this.loggedIn.next(false);


    if(this.cookieService.check("user")) {
      this.user.next(JSON.parse(this.cookieService.get("user")));
    } else {
      this.user.next(null);
    }
  }

  getUserRoles(): string[] {
    return decode(this.getToken())[this.role_path];
  }

  getUserId(): number {
    return decode(this.getToken())[this.id_path];
  }

  getUsername(): string {
    return decode(this.getToken())[this.username_path];
  }


  getToken(): string {
    if(this.cookieService.check(this.token_name))
      return this.cookieService.get(this.token_name);
    return null;
  }

  removeTokens(): void {
    this.loggedIn.next(false);
    this.user.next(null);
    this.cookieService.delete(this.token_name);
    this.cookieService.delete("user");
  }

  isAuthenticated(): Observable<boolean> {
    return this.loggedIn.asObservable();
  }

  isAdmin(): Observable<boolean> {
    return this.hasRole(Roles.Admin);
  }

  isNormalUser() : Observable<boolean> {
    return this.loggedIn.pipe(map(x => x && !Array.isArray(this.getUserRoles()) && this.getUserRoles().toString() == this.normalUserRole));
  }

  hasRole(role: Roles): Observable<boolean> {
    return this.loggedIn.asObservable().pipe(map(x => x && this.getToken() != null && this.getUserRoles().indexOf(role) > -1));
  }

  setUser(user : User) : void {
    this.cookieService.set("user", JSON.stringify(user));
    this.user.next(user);
  }

  getUser() : Observable<User> {
    return this.user.asObservable();
  }

  setToken(token: string): void {
    this.cookieService.set(this.token_name, token);
    this.loggedIn.next(true);
  }
}
