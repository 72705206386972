import { Validator, ValidationErrors, AbstractControl, ValidatorFn, FormGroup, NG_VALIDATORS } from "@angular/forms";
import { Directive } from "@angular/core";

@Directive({
  selector: '[TimeSpanFormatValidator]',
  providers: [{ provide: NG_VALIDATORS, useExisting: TimeSpanValidatorDirective, multi: true }]
})
export class TimeSpanValidatorDirective implements Validator {
  validate(control: AbstractControl): ValidationErrors {
    return timeSpanValidator(control)
  }
}

export const timeSpanValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  var val = control.value;
  var regex = new RegExp(/^([0-9]{1,2}:)?[0-9]{1,2}:[0-9]{2}(\.[0-9]{1,7})?$/);
  regex.test(val)
  return !regex.test(val) ? { 'timespaninvalidformat': true } : null;
};
