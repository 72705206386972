import { Component, Inject } from '@angular/core';
import {Subject} from 'rxjs';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {HttpClient} from '@angular/common/http';
import { TeamMember, Category, Gender } from '../models/TeamMember';
import { User } from '../models/User';
import { AuthService } from '../utils/services/AuthService';
import { DomSanitizer } from '@angular/platform-browser';
import { ManageTeamMemberComponent } from '../admin/teammembers/manage-teammember.component';
import {ManageBestTimeComponent} from "../admin/teammembers/manage-best-time.component ";
import {NgForm} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-change-pass',
  templateUrl: './change-password.component.html',
  styleUrls: ['change-password.component.css']
})
export class ChangePasswordComponent {

  public pass: string;
  public newpass: string;
  public repeatpass: string;

  public message: string;
  public messageType: string;

  constructor(private modal: NgbModal,private translateService: TranslateService, private httpClient: HttpClient, @Inject('BASE_URL') private baseUrl: string, private authService: AuthService, private sanitizer: DomSanitizer, private modalService: NgbModal) {

  }

  save(f: NgForm): void {
    if(f.valid) {
      this.httpClient.post(this.baseUrl + "api/account/change-pass", { oldPassword: this.pass, newPassword: this.newpass }).subscribe(x => {
        this.message = this.translateService.instant("SuccessfullyChanged");
        this.messageType = "success";
        this.letMessageDisappear();
      }, error => {
        this.message = this.translateService.instant("YouEnteredTheCurrentPasswordWrong");
        this.messageType = "danger";
      });
    }
  }


  letMessageDisappear() {
    setTimeout(() => {
      this.message = null;
    }, 4000);
  }
}
