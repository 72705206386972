import { Component, Inject } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { ManagePhotoalbumComponent } from "./manage-photoalbum.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {Album} from '../../models/Album';
import {DomSanitizer} from '@angular/platform-browser';
import {UploadPicturesComponent} from './upload-pictures.component';
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-manage-photoalbums',
  templateUrl: './manage-photoalbums.component.html',
  styleUrls: ['./manage-photoalbums.component.css']
})
export class ManagePhotoalbumsComponent {

  public message: string;
  public messageType: string;
  public albums: Album[];

  constructor(private httpClient: HttpClient, @Inject('BASE_URL') private baseUrl: string, private modalService: NgbModal, private sanitizer: DomSanitizer, private translateService: TranslateService) {
    this.loadAlbums();
  }

  loadAlbums(): void {
    this.httpClient.get<Album[]>(this.baseUrl + "api/photoalbums").subscribe(x => {
      this.albums = x;
    });
  }

  deleteAlbum(album: Album): void {
    this.httpClient.delete(this.baseUrl + "api/photoalbums/" + album.id).subscribe(x => {
      this.loadAlbums();
      this.message = this.translateService.instant("SuccessfullyDeleted");
      this.messageType = "success";
      this.letMessageDisappear();
    }, err => {
        this.message = this.translateService.instant("SomethingWentWrongWhileDeleting");
      this.messageType = "danger";
    });
  }

  addNewAlbum(): void {
    const modalRef = this.modalService.open(ManagePhotoalbumComponent);
    modalRef.result.then((x: any) => {
      this.loadAlbums();
    });
  }

  uploadPictures(album : Album) : void {
    const modalRef = this.modalService.open(UploadPicturesComponent, { size : 'lg' });
    modalRef.componentInstance.setAlbum(album);
    modalRef.result.then((x: any) => {
      this.loadAlbums();
    });
  }

  editAlbum(album: Album): void {
    const modalRef = this.modalService.open(ManagePhotoalbumComponent, { size: 'lg'});
    modalRef.componentInstance.setAlbum(album);
    modalRef.result.then((x: any) => {
      this.loadAlbums();
    });
  }

  letMessageDisappear() {
    setTimeout(() => {
      this.message = null;
    }, 4000);
  }
}
