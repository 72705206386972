import {Component, Inject} from "@angular/core";
import {Lightbox, LightboxConfig} from "ngx-lightbox";
import {HttpClient} from "@angular/common/http";
import {Mail} from "../models/Mail";
import {TranslateService} from "@ngx-translate/core";
import {NgForm} from "@angular/forms";
import {DatePipe} from "@angular/common";

@Component({
  selector: 'app-recrea',
  templateUrl: './recrea.component.html',
  styleUrls: ['./recrea.component.css']
})
export class RecreaComponent {
 public showSubscription = false;
 public error: string;
 public showMessage: boolean;

 public name: string;
 public firstname: string;
 public streetAndNumber: string;
 public city: string;
 public postalcode: string;
 public birthdate: Date;
 public phoneNumber: string;
 public phoneNumberParents: string;
 public emailAddress: string;
 public emailAddressParents: string;

 public adult = true;

  pictures: any[];

 constructor(@Inject('BASE_URL') private baseUrl: string, private lightbox: Lightbox, private lighboxConfig: LightboxConfig, private httpClient: HttpClient, private translateService: TranslateService, private dateFormat: DatePipe) {
   this.lighboxConfig.alwaysShowNavOnTouchDevices = true;
   this.lighboxConfig.wrapAround = true;
   this.lighboxConfig.fadeDuration = 0.3;
   this.lighboxConfig.resizeDuration = 0.3;
   this.pictures = [];
   this.pictures.push({
     src: this.baseUrl + "assets/images/recrea/flyer-front.png",
     thumb: this.baseUrl + "assets/images/recrea/flyer-front.png"
  });
   this.pictures.push({
     src: this.baseUrl + "assets/images/recrea/flyer-back.png",
     thumb: this.baseUrl + "assets/images/recrea/flyer-back.png"
   });
 }

 toggleSubscription(): void {
   this.showSubscription = !this.showSubscription;
 }

 public onDateSelect($ev): void {
   this.adult = this.getAge(this.birthdate) >= 18;
 }

  open(index:number) {
    this.lightbox.open(this.pictures, index);
  }

  close(): void {
    // close lightbox programmatically
    this.lightbox.close();
  }

  subscribe(form: NgForm): void {
   this.httpClient.post(this.baseUrl + "api/recrea", { name:this.name, firstname:this.firstname, streetAndNumber: this.streetAndNumber, city: this.city, postalCode: this.postalcode,
   birthdate: this.dateFormat.transform(this.birthdate, 'yyyy-MM-dd'), phoneNumber: this.phoneNumber, emailAddress:this.emailAddress, phoneNumberParents: this.phoneNumberParents, emailAddressParents:this.emailAddressParents}).subscribe(x => {
     this.error = null;
     this.showMessage = true;
     form.reset();
     setTimeout(() => this.showMessage = false, 6000);
   }, error => {
     this.error = this.translateService.instant("TryAgain");
     this.showMessage = true;
     setTimeout(() => this.showMessage = false, 6000);
   });
  }

  private getAge(birthdate: Date) {
    var today = new Date();
    var age = today.getFullYear() - birthdate.getFullYear();
    var m = today.getMonth() - birthdate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthdate.getDate())) {
      age--;
    }
    return age;
  }
}
