import {Component, Inject} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Partner } from '../models/Partner';

@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls:['./partners.component.css']
})
export class PartnersComponent {

  public partners: Partner[];

  constructor(private httpClient: HttpClient, @Inject('BASE_URL') private baseUrl: string, private sanitizer: DomSanitizer, private modalService: NgbModal) {
    this.loadPartners();
  }

  loadPartners() {
    this.httpClient.get<Partner[]>(this.baseUrl + "api/partners").subscribe(x => {
      this.partners = x;
      this.partners.forEach(partner => {
        if (partner.banner)
          partner.bannerImage = this.sanitizer.bypassSecurityTrustUrl(partner.banner);
      });
    });
  }
}
