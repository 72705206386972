import { Component } from '@angular/core';
import {SpinnerService} from "./utils/services/SpinnerService";
import {Observable} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {NavigationEnd, Router} from "@angular/router";
import {Title} from "@angular/platform-browser";
import {filter, map} from "rxjs/operators";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  public spinnerText: Observable<string>;
 public sponsorsActive: boolean;

  constructor(private spinner: SpinnerService,private translate: TranslateService, private router: Router, private titleService: Title) {
    this.sponsorsActive = true;
    this.spinnerText = this.spinner.text();
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('be');
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use('be');

    this.router.events.pipe(filter((event) => event instanceof NavigationEnd ))
      .pipe(map(() => this.router))
      .subscribe((event) => {
         this.translate.get('be').subscribe(dummy => {
           const title = this.getTitle(this.router.routerState, this.router.routerState.root).join(' | ');
           this.titleService.setTitle("ATK - " + title);
         });
        }
      );

  }
  title = 'app';

  getTitle(state, parent) {
    const data = [];
    //debugger;
    if (parent && parent.snapshot.data && parent.snapshot.data.title) {
      data.push(this.translate.instant(parent.snapshot.data.title));
    }

    if (state && parent) {
      data.push(... this.getTitle(state, state.firstChild(parent)));
    }
    return data;
  }
}
