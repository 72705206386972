import {Component, Inject} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Category, Classification, Gender, TeamMember} from '../../models/TeamMember';
import 'tinymce/tinymce.min.js';
import { TinyMceSettingsService } from '../../utils/services/TinyMceSettingsService';
import { NgForm } from '@angular/forms';
declare var tinymce: any;

const HttpUploadOptions = {
  headers: new HttpHeaders({"Content-Type": "multipart/form-data"})
};

@Component({
  selector: 'app-manage-team-member',
  templateUrl: './manage-teammember.component.html',
  styleUrls: ['./manage-teammember.component.css']
})
export class ManageTeamMemberComponent {

  public teamMember: TeamMember;
  public genders: any;
  public categories: any;
  public athleteTypes: any;
  public tinyMceSettings: any;

  public uploadedImageUrl: any;
  public pictureFile: Blob;
  private userDeletedImage: boolean;

  constructor(private httpClient: HttpClient, @Inject('BASE_URL') private baseUrl: string, public activeModal: NgbActiveModal, tinyMceService: TinyMceSettingsService) {
    this.teamMember = new TeamMember();
    this.genders = Gender;
    this.categories = Category;
    this.athleteTypes = Classification;
    this.uploadedImageUrl = "assets/images/no-image.png";
    this.userDeletedImage = false;
    this.tinyMceSettings = tinyMceService.getSettings("100%", "320px");
  }

  setTeamMember(member: TeamMember): void {
    this.teamMember = member;
    if(member.pictureInBase64 != null && member.safeUrl != null)
      this.uploadedImageUrl = member.safeUrl;
  }
  deleteImage(): void {
    this.userDeletedImage = true;
    this.uploadedImageUrl = "assets/images/no-image.png";
  }

  save(form: NgForm): void {
    if (form.valid) {
      let payload = new FormData();
      payload.append('category', this.teamMember.category.toString());
      if (this.teamMember.categorySubType != null)
        payload.append('categorySubType', this.teamMember.categorySubType);
      payload.append('classification', this.teamMember.classification.toString());
      payload.append('firstName', this.teamMember.firstName);
      payload.append('gender', this.teamMember.gender.toString());
      payload.append('lastName', this.teamMember.lastName);

      if (this.teamMember.bio != null)
        payload.append('bio', this.teamMember.bio);

      if (this.teamMember.facebook != null)
        payload.append('facebook', this.teamMember.facebook);

      if (this.teamMember.instagram != null)
        payload.append('instagram', this.teamMember.instagram);

      payload.append('birthDate', this.teamMember.birthDate == null ? null : this.teamMember.birthDate.toISOString());
      if (this.pictureFile != null)
        payload.append('picture', this.pictureFile);

      if (this.pictureFile == null && this.userDeletedImage)
        payload.append('deleteImage', "true");

      if (this.teamMember && this.teamMember.user && this.teamMember.user.id) {
        payload.append('user.id', "" + this.teamMember.user.id);
      }

      if (this.teamMember.id && this.teamMember.id != 0) {
        this.httpClient.put(this.baseUrl + 'api/team/' + this.teamMember.id, payload).subscribe(x => {
          this.activeModal.close(this.teamMember);
        });
      } else {
        this.httpClient.post(this.baseUrl + 'api/team', payload).subscribe(x => {
          this.activeModal.close(this.teamMember);
        });
      }
    }
  }

  uploadFile($event): void {
    let files = $event.target.files;

    if (files) {
      let reader = new FileReader();
      reader.onload = (e: any) => {
        this.uploadedImageUrl = e.target.result;
      };
      reader.readAsDataURL(files[0]);
      this.pictureFile = files[0];
    }
  }
}
