import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root',
})
export class ConverterService {
  convertToFormData(obj: any) : FormData {
    const formData = new FormData();
    for (const key in obj ) {
      formData.append(key, obj[key]);
    }
    return formData;
  }
}
