import {Component, Inject} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Event} from '../../models/Event';
import { Report } from '../../models/Report';
import { TinyMceSettingsService } from '../../utils/services/TinyMceSettingsService';
import { NgForm } from '@angular/forms';
import {Sponsor, SponsorType} from "../../models/Sponsor";

@Component({
  selector: 'app-manage-sponsor',
  templateUrl: './manage-sponsor.component.html',
  styleUrls:['./manage-sponsor.component.css']
})
export class ManageSponsorComponent {

  public message: string;
  public  messageType: string;

  public tinyMceSettings: any;

  public sponsor: Sponsor;
  public sponsorTypes: any;

  constructor(private httpClient: HttpClient, @Inject('BASE_URL') private baseUrl: string, public activeModal: NgbActiveModal, tinyMceService: TinyMceSettingsService) {
    this.sponsorTypes = SponsorType;
    this.sponsor = new Sponsor();
    this.tinyMceSettings = tinyMceService.getSettings("100%", "320px");
  }

  setSponsor(spo: Sponsor): void {
    this.sponsor = spo;
  }

  save(form: NgForm): void {
    if (form.valid) {
      if (!this.sponsor.id || this.sponsor.id == 0) {
        this.httpClient.post(this.baseUrl + "api/sponsors", this.sponsor).subscribe(x => {
          this.activeModal.close();
        });
      } else {
        this.httpClient.put(this.baseUrl + "api/sponsors", this.sponsor).subscribe(x => {
          this.activeModal.close();
        });
      }
    }
  }
}
