import {Component, Inject} from '@angular/core';
import {Coach} from "../models/Coach";
import {HttpClient} from "@angular/common/http";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {DomSanitizer} from "@angular/platform-browser"
import {CoachDetailComponent} from "./coach-detail.component";

@Component({
  selector: 'app-coaches',
  templateUrl: './coaches.component.html',
  styleUrls:['./coaches.component.css']
})
export class CoachesComponent {

  public coaches: Coach[];

  constructor(private httpClient: HttpClient, @Inject('BASE_URL') private baseUrl: string, private modalService: NgbModal, private sanitizer: DomSanitizer) {
    this.loadCoaches();
  }

  details(coach: Coach): void {
    const modalRef = this.modalService.open(CoachDetailComponent, { size: "lg" });
    modalRef.componentInstance.setCoach(coach);
  }

  loadCoaches() {
    this.httpClient.get<Coach[]>(this.baseUrl + "api/coaches").subscribe(x => {
      x.forEach(coach => {
        if (coach.pictureInBase64) {
        }
        coach.safeUrl = this.sanitizer.bypassSecurityTrustUrl(coach.pictureInBase64);
      });
      this.coaches = x;
    });
  }
}
