import {Component, Inject} from "@angular/core";
import {HttpClient} from '@angular/common/http';
import {Report} from "../../models/Report";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {TranslateService} from "@ngx-translate/core";
import {ManageReportComponent} from "../reports/manage-report.component";
import {ManageSponsorComponent} from "./manage-sponsor.component";
import {Sponsor, SponsorType} from "../../models/Sponsor";
import {DomSanitizer} from "@angular/platform-browser";
import {Info, InfoType} from "../../models/Info";
import {ManageInfoComponent} from "./manage-info.component";

@Component({
  selector: 'app-manage-other',
  templateUrl: './manage-other.component.html',
  styleUrls: ['./manage-other.component.css']
})
export class ManageOtherComponent {

  public message: string;
  public messageType: string;
  public information: Info[];

  private types:any;

  constructor(private dialogService: NgbModal, private httpClient: HttpClient, @Inject('BASE_URL') private baseUrl: string, private sanitizer: DomSanitizer, private modalService: NgbModal, private translateService: TranslateService) {
    this.types = InfoType;
    this.loadInfo();
  }

  loadInfo(): void {
    this.httpClient.get<Info[]>(this.baseUrl + "api/info").subscribe(x => {
      this.information = x;
    });
  }

  addNewItem() {
    const dialog = this.dialogService.open(ManageInfoComponent);
    dialog.result.then(x => {
      this.loadInfo();
    });
  }

  edit(info: Info): void {
    const dialog = this.dialogService.open(ManageInfoComponent);
    dialog.componentInstance.information = info;
    dialog.result.then(x => {
      this.loadInfo();
    });
  }

  getType(type: InfoType): string {
    return InfoType[type];
  }

  deleteInfo(id: number): void {
    this.httpClient.delete(this.baseUrl + "api/info/" + id).subscribe(x => {
      this.loadInfo();
    });
  }

  letMessageDisappear() {
    setTimeout(() => {
      this.message = null;
    }, 4000);
  }
}
