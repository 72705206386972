import { Injectable, Inject } from '@angular/core';
declare var tinymce: any;

@Injectable({
  providedIn: 'root',
})
export class TinyMceSettingsService {

  constructor(@Inject('BASE_URL') private baseUrl: string) {
  }

  getSettings(width: string, height: string): any {
    tinymce.baseURL = this.baseUrl + 'assets/tinymce';

    return {
      inline: false,
      statusbar: false,
      forced_root_block: false,
      height: height,
      width: width,
      plugins: 'image',
      relative_urls: false,
      images_upload_url: '/api/content/images/uploads',
      images_upload_credentials: true,
      paste_data_images : true
    };
  }
}
