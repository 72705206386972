import {Component} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { OwnCalendarEvent } from '../models/Event';
import {Observable} from "rxjs";
import {AuthService} from "../utils/services/AuthService";

@Component({
  selector: 'app-calendar-event-detail',
  templateUrl: './calendar-event-detail.component.html',
  styleUrls:['calendar-event-detail.component.css']
})
export class CalendarEventDetailComponent {

  public calendarEvent: OwnCalendarEvent;

  public isAuthenticated: Observable<boolean>;

  constructor(public activeDialog: NgbActiveModal,private authService: AuthService) {
    this.isAuthenticated = this.authService.isAuthenticated();

  }
}
