import {Component, Inject} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {Info} from "../models/Info";
import {FileSaverService} from "ngx-filesaver";
import {Documents} from "../models/Documents";

@Component({
  selector: 'app-internal-rules',
  templateUrl: './rules.component.html',
  styleUrls:['./rules.component.css']
})
export class InternalRulesComponent {

  public internalRules: Documents;

  constructor(private httpClient: HttpClient, @Inject('BASE_URL') private baseUrl: string, private sanitizer: DomSanitizer, private modalService: NgbModal, private fileSaverService: FileSaverService) {
    this.internalRules = Documents.InternalRules;
  }

  download(doc: Documents): void {
    this.httpClient.get(this.baseUrl + "api/content/default-docs/" + doc, {
      responseType: 'blob',
      observe: 'response'
    }).subscribe((res:any) => {
      var filename = this.getFileNameFromHttpResponse(res);
      this.fileSaverService.save(res.body, filename);
    });
  }

  getFileNameFromHttpResponse(httpResponse) : string {
    var contentDispositionHeader = httpResponse.headers.get('Content-Disposition');
    var result = contentDispositionHeader.split(';')[1].trim().split('=')[1];
    return result.replace(/"/g, '');
  }
}
