import {SafeUrl} from "@angular/platform-browser";

export class Partner {
  id: number;
  name: string;
  banner: string;
  bannerImage: SafeUrl;
  website: string;
  extraInfo: string;
}
