import {Component, Inject} from "@angular/core";
import {HttpClient} from '@angular/common/http';
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {TranslateService} from "@ngx-translate/core";
import {DomSanitizer} from "@angular/platform-browser";
import {Info, InfoType} from "../../models/Info";
import {TinyMceSettingsService} from "../../utils/services/TinyMceSettingsService";
import {ConverterService} from "../../utils/services/ConverterService";
import {NgForm} from "@angular/forms";

@Component({
  selector: 'app-manage-info',
  templateUrl: './manage-info.component.html',
  styleUrls: ['./manage-info.component.css']
})
export class ManageInfoComponent {

  public information: Info;
  public tinyMceSettings: any;
  public chooseFileText: string;
  public types:any;

  constructor(public activeModal: NgbActiveModal,  private httpClient: HttpClient, @Inject('BASE_URL') private baseUrl: string, private sanitizer: DomSanitizer, private modalService: NgbModal, private translateService: TranslateService, tinyMceService: TinyMceSettingsService, private converter: ConverterService) {
    this.information = new Info();
    this.types = InfoType;
    this.chooseFileText = this.translateService.instant('ChooseFile');
    this.tinyMceSettings = tinyMceService.getSettings("100%", "320px");
  }

  fileChanged(event) {
    let files = event.target.files;
    this.information.file = files[0];
    this.chooseFileText = files[0].name;
  }

  save(form: NgForm): void {
    if (form.valid) {
      if (this.information.id && this.information.id != 0) {
        this.httpClient.put(this.baseUrl + "api/info/" + this.information.id, this.information).subscribe(x => {
          this.activeModal.close();
        });
      } else {
        const payload = this.converter.convertToFormData(this.information);
        if (this.information.type == InfoType.Document) {
          payload.append("file", this.information.file);
        }
        this.httpClient.post(this.baseUrl + "api/info", payload).subscribe(x => {
          this.activeModal.close();
        });
      }
    }
  }
}
