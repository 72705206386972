import {Component, Inject} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Gender} from '../../models/TeamMember';
import {Coach} from '../../models/Coach';
import {TinyMceSettingsService} from "../../utils/services/TinyMceSettingsService";
import {NgForm} from "@angular/forms";

const HttpUploadOptions = {
  headers: new HttpHeaders({"Content-Type": "multipart/form-data"})
}

@Component({
  selector: 'app-manage-coach',
  templateUrl: './manage-coach.component.html',
  styleUrls: ['./manage-coach.component.css']
})
export class ManageCoachComponent {

  public coach: Coach;
  public genders: any;
  public tinyMceSettings: any;

  public uploadedImageUrl: any;
  public pictureFile: Blob;
  private userDeletedImage: boolean;

  constructor(private httpClient: HttpClient, @Inject('BASE_URL') private baseUrl: string, public activeModal: NgbActiveModal, tinyMceService: TinyMceSettingsService) {
    this.coach = new Coach();
    this.genders = Gender;
    this.uploadedImageUrl = "assets/images/no-image.png";
    this.userDeletedImage = false;
    this.tinyMceSettings = tinyMceService.getSettings("100%", "320px");
  }

  setCoach(c: Coach): void {
    this.coach = c;
    if (c.pictureInBase64 != null && c.safeUrl != null)
      this.uploadedImageUrl = c.safeUrl;
  }

  deleteImage(): void {
    this.userDeletedImage = true;
    this.uploadedImageUrl = "assets/images/no-image.png";
  }

  save(form: NgForm): void {
    if (form.valid) {
      let payload = new FormData();
      payload.append('description', this.coach.description);
      payload.append('certificates', JSON.stringify(this.coach.certificates));
      payload.append('firstName', this.coach.firstName);
      payload.append('gender', this.coach.gender.toString());
      payload.append('lastName', this.coach.lastName);

      if (this.pictureFile != null)
        payload.append('picture', this.pictureFile);

      if (this.pictureFile == null && this.userDeletedImage)
        payload.append('deleteImage', "true");

      if (this.coach.id && this.coach.id != 0) {
        this.httpClient.put(this.baseUrl + 'api/coaches/' + this.coach.id, payload).subscribe(x => {
          this.activeModal.close(this.coach);
        });
      } else {
        this.httpClient.post(this.baseUrl + 'api/coaches', payload).subscribe(x => {
          this.activeModal.close(this.coach);
        });
      }
    }
  }

  uploadFile($event): void {
    let files = $event.target.files;

    if (files) {
      let reader = new FileReader();
      reader.onload = (e: any) => {
        this.uploadedImageUrl = e.target.result;
      };
      reader.readAsDataURL(files[0]);
      this.pictureFile = files[0];
    }
  }
}
