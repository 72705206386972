import { SafeUrl } from "@angular/platform-browser";

export class TeamMember {
  id: number;
  firstName: string;
  lastName: string;
  pictureInBase64: string;
  safeUrl: SafeUrl;
  category: Category;
  categorySubType: string;
  bio: string;
  facebook?: string;
  instagram?: string;
  birthDate: Date;
  bestResults: BestTime[];
  gender: Gender;
  user: CoupledUser;
  classification: Classification;
}

export class CoupledUser {
  id: number;
  username: string;
}

export class BestTime {
  id: number;
  distance: string;
  bestTime: string;
  bestTimeFormatted: string;
}

export enum Category {
  Benjamin,
  Pupil,
  Miniem,
  Cadet,
  Scholier,
  Junior,
  Senior,
  Master
}

export enum Gender {
  Female,
  Male
}

export enum Classification {
  Elite,
  Top,
  Normal,
  Recreant
}
