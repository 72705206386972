import {Component, Inject, Sanitizer} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {Sponsor, SponsorType} from "../models/Sponsor";

@Component({
  selector: 'app-sponsors',
  templateUrl: './sponsors.component.html',
  styleUrls:['./sponsors.component.css']
})
export class SponsorsComponent {

  public sponsors: Sponsor[];
  private types: any;

  constructor(private httpClient: HttpClient, @Inject('BASE_URL') private baseUrl: string, private sanitizer: DomSanitizer, private modalService: NgbModal) {
    this.loadSponsors();
    this.types = SponsorType;
  }

  loadSponsors() {
    this.httpClient.get<Sponsor[]>(this.baseUrl + "api/sponsors").subscribe(x => {
      this.sponsors = x;
      this.sponsors.forEach(sponsor => {
        if(sponsor.banner)
          sponsor.bannerImage = this.sanitizer.bypassSecurityTrustUrl(sponsor.banner);
      });
    });
  }

  getType(type: SponsorType) : string {
    return this.types[type];
  }
}
