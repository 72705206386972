import { SafeUrl } from "@angular/platform-browser";
import {Gender} from './TeamMember';
import { Picture } from "./Picture";

export class Album {
  id: number;
  name: string;
  created: Date;
  lastUpdated: Date;
  mainPicture: string;
  numberOfPictures: number;
  pictures: Picture[];
  thumbnailPath?: string;
  mainPictureId?: number;
}
