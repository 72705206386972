import {Component, Inject} from '@angular/core';
import {Album} from '../models/Album';
import {HttpClient} from '@angular/common/http';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {DomSanitizer} from '@angular/platform-browser';
import {Picture} from '../models/Picture';
import {Router} from '@angular/router';

@Component({
  selector: 'app-photo-album-overview',
  styleUrls: ['./photo-album-overview.component.css'],
  templateUrl: './photo-album-overview.component.html',
})
export class PhotoAlbumOverviewComponent {

  public albums: Album[];

  constructor(private httpClient: HttpClient, @Inject('BASE_URL') private baseUrl: string, private sanitizer: DomSanitizer, private router: Router) {
    this.albums = [];
    this.loadAlbums();
  }

  loadAlbums() {
    this.httpClient.get<Album[]>(this.baseUrl + "api/photoalbums").subscribe(x => {
      this.albums = x;
    });
  }

  details(album: Album) {
      this.router.navigateByUrl("/photo-albums/" + album.id);
  }
}
