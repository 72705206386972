import {Component, Inject} from '@angular/core';
import {Album} from '../models/Album';
import {HttpClient} from '@angular/common/http';
import {DomSanitizer} from '@angular/platform-browser';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {Lightbox, LightboxConfig} from 'ngx-lightbox';

@Component({
  selector: 'app-photo-album',
  styleUrls: ['./photo-album.component.css'],
  templateUrl: './photo-album.component.html',
})
export class PhotoAlbumComponent {

  public album: Album;
  public albumId: number;

  pictures: any[];

  constructor(private httpClient: HttpClient, @Inject('BASE_URL') private baseUrl: string,
              private route: ActivatedRoute, private sanitizer: DomSanitizer,
              private lightbox: Lightbox, private lighboxConfig: LightboxConfig) {
    this.album = new Album();
    this.pictures = [];
    this.lighboxConfig.alwaysShowNavOnTouchDevices = true;
    this.lighboxConfig.wrapAround = true;
    this.lighboxConfig.fadeDuration = 0.3;
    this.lighboxConfig.resizeDuration = 0.3;
    this.loadAlbum();
  }

  open(index:number) {
    this.lightbox.open(this.pictures, index);
  }

  close(): void {
    // close lightbox programmatically
    this.lightbox.close();
  }

  loadAlbum() {
    this.route.paramMap.subscribe(params => {
      this.albumId = +params.get('albumId');
      this.httpClient.get<Album>(this.baseUrl + 'api/photoalbums/' + this.albumId).subscribe(x => {
        for(var i = 0; i < x.pictures.length;i++) {
          const picture = x.pictures[i];
          if (picture) {
            this.pictures.push({
              src: 'uploads/' + picture.imagePath,
              thumb: 'uploads/' + picture.thumbnailPath
            });
          }
        }
        this.album = x;
      });
    });
  }
}
