import { Component, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { User } from '../../models/User';
import { AuthService } from '../../utils/services/AuthService';
import { AddUserComponent } from './add-user.component';
import {TranslateService} from '@ngx-translate/core';
import {Observable, combineLatest} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-manage-users',
  templateUrl: './users.component.html',
  styleUrls: ['users.component.css']
})
export class ManageUsersComponent {
  public users: User[];
  public currentUserId: number;
  public messageType: string;
  public message: string;

  constructor(private httpClient: HttpClient, @Inject('BASE_URL') private baseUrl: string, private sanitizer: DomSanitizer, private modalService: NgbModal, private authService: AuthService, private translateService: TranslateService) {
    this.currentUserId = this.authService.getUserId();
    this.loadUsers();
  }

  loadUsers(): void {
    this.httpClient.get<User[]>(this.baseUrl + "api/users").subscribe(x => {;
      this.users = x;
    });

  }
  addNewUser(): void {
    const modal = this.modalService.open(AddUserComponent);
    modal.result.then(x => {
      this.loadUsers();
    });
  }

  editUser(user: User): void {
    const modal = this.modalService.open(AddUserComponent);
    modal.componentInstance.setUser(user);
    modal.result.then(x => {
      this.loadUsers();
    });
  }

  translate(roles: string[]) : Observable<string[]> {
    return this.translateService.get(roles.map(x => "Roles." + x)).pipe(map(x => Object.keys(x).map(key => x[key])));

    //return combineLatest(translated).pipe(map(y => y.join(", ")));
  }

  deleteUser(user: User): void {
    this.httpClient.delete(this.baseUrl + "api/users/" + user.id).subscribe(x => {
      this.loadUsers();
    });
  }
}
