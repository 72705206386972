import {Component, Inject} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {DomSanitizer} from '@angular/platform-browser';
import {NgbModal, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {User} from '../../models/User';
import {AuthService} from '../../utils/services/AuthService';
import {NgForm} from "@angular/forms";

@Component({
  selector: 'app-manage-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['add-user.component.css']
})
export class AddUserComponent {

  public message: string;
  public messageType: string;
  public user: User;
  public roles: any[];
  public existingUser: boolean;

  constructor(private httpClient: HttpClient, @Inject('BASE_URL') private baseUrl: string, private sanitizer: DomSanitizer, public activeModal: NgbActiveModal, private authService: AuthService) {
    this.existingUser = false;
    this.user = new User();
    httpClient.get<any[]>(this.baseUrl + "api/users/possible-roles").subscribe(x => {
      this.roles = x;
      this.roles.forEach(x => {
        x.checked = x.id == 1;
      });
    });
  }

  setUser(u: User): void {
    this.user = u;
    this.existingUser = this.user && this.user.id != 0;
    this.httpClient.get<any[]>(this.baseUrl + "api/users/possible-roles").subscribe(x => {
      this.roles = x;
      this.roles.forEach(x => {
        x.checked = x.id == 1 || u.roles.indexOf(x.name) > -1;
      });
    });
  }

  save(form: NgForm): void {
    if (form.valid) {
      this.user.roles = this.roles.filter(x => x.checked).map(x => x.name);
      if(this.existingUser) {
        this.httpClient.put(this.baseUrl + "api/users/" + this.user.id, this.user).subscribe(x => {
            this.activeModal.close();
          },
          (error: HttpErrorResponse) => {
            if (error.status) {
              this.message = "De gebruikersnaam bestaat al";
              this.messageType = "danger";
            }
          });
      } else
      {
        this.httpClient.post(this.baseUrl + "api/users", this.user).subscribe(x => {
            this.activeModal.close();
          },
          (error: HttpErrorResponse) => {
            if (error.status) {
              this.message = "De gebruikersnaam bestaat al";
              this.messageType = "danger";
            }
          });
      }

    }
  }
}
